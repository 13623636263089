import WarningBox from "../../component/warningBox";
//获取我的行程类型
import consumer_journey_journeyType from '@/lib/data-service/haolv-default/consumer_journey_journeyType'
//获取我的行程列表
import getMyJourneyList from '@/lib/data-service/haolv-default/consumer_journey_myJourneyList'

export default {
    data() {
        return {
            dateArr: [],
            params: {
                startDate: '',
                endDate: '',
                journeyType: 0
            },
            journeyTypeList: [],
            myJourneyList: [],
            loading: false
        }
    },
    components: {
        WarningBox
    },
    mounted() {
        this._getMyJourneyList();
        this._getMyJourneyType();
    },
    watch: {
        dateArr(newVal) {
            let [start, end] = newVal
            this.params.startDate = start ? start + ' 00:00:00' : ''
            this.params.endDate = end ? end + ' 23:59:59' : ''
        }
    },
    methods: {
        async _getMyJourneyList() {
            this.loading = true;
            let res = await getMyJourneyList(this.params);
            let myJourneyList = res.datas;
            let dateList = [...new Set(myJourneyList.map(item => item.startDate))];
            this.myJourneyList = [...new Set(dateList.map(date => {
                    let filterList = myJourneyList.filter(item => item.startDate === date);
                    return {
                        recentDate: filterList[0].dayType,
                        startDate: date,
                        week: filterList[0].week,
                        myJourneyList: filterList
                    }
                })
            )];
            this.loading = false;
        },
        async _getMyJourneyType() {
            let res = await consumer_journey_journeyType();
            this.journeyTypeList = res.datas;
        },
        filterMyJourneyList(journeyType) {
            this.params.journeyType = journeyType;
            this._getMyJourneyList();
        },
        toJourneyDetail(evectionNo) {
            this.$router.push({
                name: "admin-evection-detail",
                query: {evectionNo, fromType: 6},
            });
        }
    }
}
